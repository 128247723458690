export default [
  {
    title: 'Accueil',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'COMMERCE',
    icon: 'HomeIcon',
    children: [
      // This is array of menu items or menu groups
      // NOTE: You can't use menu header as children
      {
        title: 'Clients & Scans',
        route: 'scans',
        icon: 'SmileIcon',
      },
      {
        title: 'Le catalogue',
        route: 'glasses',
        icon: 'ShoppingCartIcon',
      },
      {
        title: 'Nouveau scan',
        route: '/',
        icon: 'PlusIcon',
      },
    ],
  },
  {
    title: 'GESTION',
    icon: 'HomeIcon',
    children: [
      // This is array of menu items or menu groups
      // NOTE: You can't use menu header as children
      
      {
        title: 'Commandes',
        route: 'orders',
        icon: 'ShoppingCartIcon',
      },
      {
        title: 'Clients',
        route: 'users',
        icon: 'UsersIcon',
      }
    ],
  },
]
